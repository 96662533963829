<template>
    <div class="application-listing-overview">
        <h1>{{ $t('Applications') }}</h1>
        <div class="form-group">
            <input v-model.trim="searchApplications" @input="debounceGetApplications" type="text" class="form-control" :placeholder="$t('Search for application')"/>
        </div>
        <ul>
            <li>
                <a @click="getVacancies('pending')">
                    <p>{{ $t('Awaiting Applications') }}</p>
                    <span>{{ summary.pending || 0 }}</span>
                </a>
            </li>
            <li>
                <a @click="getVacancies('approved')">
                    <p>{{ $t('Approved Applications') }}</p>
                    <span>{{ summary.approved || 0 }}</span>
                </a>
            </li>
            <li>
                <a @click="getVacancies('rejected')">
                    <p>{{ $t('Rejected Applications') }}</p>
                    <span>{{ summary.rejected || 0 }}</span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import { applicationService } from "@/services/applicationService";
import _ from "lodash";

export default {
    name: "ApplicationsLeftSidebarComponent",
    data() {
        return {
            status: 'pending',
            summary: {},
            searchApplications: '',
            vacancies: {
                pending: [],
                approved: [],
                rejected: [],
            }
        }
    },
    created() {
        this.getSummary()
    },
    methods: {
        getVacancies(status) {
            this.status = status;
            this.$emit('loadVacancies', {
                status: this.status,
                query: this.searchApplications  
            })
        },
        getSummary() {
            applicationService.summary({
                "q": this.searchApplications
            }).then(response => {
                this.summary = response.data.data
            })
        },
        debounceGetApplications: _.debounce(function () {
            this.getSummary();
            this.getVacancies(this.status);
        }, 500),
    }
}
</script>