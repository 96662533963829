<template>
    <div class="sidebar-view">
        <LeftSidebar @loadVacancies="loadFromSidebar" />
    </div>
    <div class="content-view">
        <keep-alive>
            <component
                :is="currentComponent"
                :status="status"
                :vacancies="vacancies"
                :vacancy="vacancy"
                @loadCandidates="getCandidates"
                :candidates="candidates"
                @getApplication="getApplication"
                :application="application"
                @switchActiveComponent="setComponent"
            ></component>
        </keep-alive>
    </div>
</template>

<script>
import LeftSidebar from "@/components/applications/LeftSidebar";
import { myVacancyService } from '@/services/myVacancyService'
import { applicationService } from '@/services/applicationService'
import { defineAsyncComponent } from "vue";

export default {
    name: "ManagementApplicationsPanel",
    data() {
        return {
            currentComponent: 'vacancies',
            vacancies: [],
            vacancy: {},
            candidates: [],
            application: {},
            status: 'pending'
        }
    },
    components: {
        vacancies: defineAsyncComponent(() =>
            import("@/views/applications/user/Stage_1")
        ),
        candidates: defineAsyncComponent(() =>
            import("@/views/applications/user/Stage_2")
        ),
        application: defineAsyncComponent(() =>
            import("@/views/applications/user/Stage_3")
        ),
        LeftSidebar
    },
    created() {
        this.getVacancies();
    },
    methods: {
        getVacancies(searchQuery = '') {
            applicationService.getVacanciesByStatus(this.status, {
                q: searchQuery
            }).then(response => {
                this.vacancies = response.data.data;
            })
        },
        getCandidates(vacancy) {
            this.vacancy = vacancy;
            applicationService.getVacancyCandidatesByStatus(vacancy.id, this.status).then(response => {
                this.candidates = response.data.data;
                this.currentComponent = 'candidates';
            }).catch(err => {
                console.log(err);
            })
        },
        getApplication(application) {
            applicationService.getApplication(application).then(response => {
                this.application = response.data.data;
                this.currentComponent = 'application';
            }).catch(err => {
                console.log(err);
            })
        },
        loadFromSidebar(data) {
            const { status, query } = data;
            this.status = status;
            this.currentComponent = 'vacancies';
            this.getVacancies(query);
        },
        setComponent(component) {
            this.currentComponent = component;
        }
    },
}
</script>